<template>
  <span v-if="$store.getters.spinnerStatus" class="loading"></span>
  <HeaderComponent/>
  <router-view />
  <div class="version">{{$store.state.app.version}} v</div>
</template>
<script>

</script>

<style lang="scss">
  @import "@/assets/style/styles.scss";

  .version {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: lightgray;
    bottom: 0;
    align-content: center;
  }
</style>
