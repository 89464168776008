<template>
  <div class="error-msg">
    <div v-if="errors.length > 0" class="errors">
      <!-- <p v-for="(error, i) in errors" :key="i">{{ error }}</p> -->
      <p>{{ errors[0] }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";
.error-msg {
  height: 20px;
  padding: 5px 0;
 // margin-bottom: 10px;
  .errors {
    font-size: 0.875rem;
    color: $red-400;
    p {
      word-wrap: break-word;
      margin: 0;
    }
  }
}
</style>
