import axios from "axios";
import { useToast } from "vue-toastification";
import store from "@/store";
const isLocal = window.origin.includes("http://localhost:8080");
const defaultConfig = {
  baseURL: isLocal ? "http://localhost:3031/api" : "http://185.66.71.212:3031/api",
  headers: {
    "Content-type": "application/json",
  },
};

const token = localStorage.getItem("m2token");

if (token) {
  defaultConfig.headers["Authorization"] = `Bearer ${token}`;
}

export const DefaultAPIInstance = axios.create(defaultConfig);

DefaultAPIInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const toast = useToast();
    if (401 === error.response.status) {
      localStorage.removeItem("m2token");
      location.reload();
      toast.error("Сессия была сброшена, войдите заново!");
    } else if ([400, 403, 404, 500].includes(error.response.status)) {
      const toast = useToast();
      toast.error(error.response?.data?.message);
      store.dispatch('spinnerStatus',false);
    } else {
      return Promise.reject(error);
    }
  }
);
