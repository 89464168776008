
import store from "@/store";
const isAuthorized = () => localStorage.hasOwnProperty("m2token") && ['superadmin','admin'].includes(store.state.credentials.role);
const isSuperAdmin = () => store.getters.isSuperAdmin;
const authGuard = function (to, from, next) {
  if (!isAuthorized()) next({ name: "login" });
  else next();
};

const isSAdmin = function (to, from, next) {
  if (!isAuthorized || !isSuperAdmin()) next({ name: "login" });
  else next();
};

export {
    authGuard,
    isSAdmin
}