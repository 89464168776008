import store from "@/store";
const role = {
  name: "role",
  directive: {
    mounted(el, binding) {
      el.style.display =
      store.state.credentials.role == binding.value ? "block" : "none" ;
      const unwatch = store.watch(
        (state) => state.credentials.role,
        (role) => (el.style.display = role == binding.value ? "block" : "none")
      );
      el.__role_unwatch__ = unwatch;
    },

    unbind() {
      el.__role_unwatch__ && el._role__unwatch__();
    },
  },
};
export default role;
