<template>
    <button
      :class="['button', type, { disabled }]"
      :type="htmlType"
      :disabled="disabled"
    >
      <slot></slot>
    </button>
  </template>
  
  <script>
  export default {
    props: {
      htmlType: {
        type: String,
        default: "button",
        validator: htmlType => ["button", "reset", "submit"].includes(htmlType)
      },
      type: {
        type: String,
        default: "default",
        validator: type => ["default", "primary", "danger"].includes(type)
      },
      disabled: {
        type: Boolean
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/style/colors.scss";
  @import "@/assets/style/constants.scss";
  .disabled {
    cursor: not-allowed !important;
    background-color: $gray-100 !important;
    border-color: $gray-400 !important;
    opacity: 0.3;
  }
  
  .button {
    display: block;
    color: black;
    outline: none;
    cursor: pointer;
    background: transparent;
    border-width: 1px;
    border-color: $gray-400;
    box-shadow: none;
    padding: 0.6rem 1.8rem;
    border-radius: 0.25rem;
    min-width: 6rem;
    transition: all 100ms;
  }
  
  .default:hover {
    border-color: $green-500;
  }
  
  .default:focus {
    border-color: $green-500;
    box-shadow: 0 0 3px $green-500;
  }
  
  .primary {
    background-color: $green-500;
    color: white;
    border-color: $green-800;
  }

  .reset {
    border: 1px $color-blue solid;
    background-color: white;
    color: $color-blue;
  }

  .m2style {
    background-color: $color-blue;
    color: white;
    border-color: $green-800;
  }
  .m2style:hover {
    background-color: $color-blue-hover;
  }
  
  .primary:hover {
    background-color: $green-700;
  }
  
  .primary:focus {
    box-shadow: 0 0 3px $green-500;
  }
  
  .danger {
    background-color: $red-50;
    border-color: $red-500;
  }
  
  .danger:hover {
    background-color: $red-100;
  }
  
  .danger:focus {
    box-shadow: 0 0 3px $red-500;
  }
  </style>