import * as Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue3 from "bootstrap-vue-3";
import components from "@/components/UI";
import directives from "@/directives";
import Toast from "vue-toastification";
import VueAxios from "vue-axios";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "vue-toastification/dist/index.css";
import SvgIcon from "vue3-icon";

const app = Vue.createApp(App);
app.component("svg-icon", SvgIcon);

components.forEach((component) => {
  app.component(component.name, component);
});

directives.forEach(({ name, directive }) => {
  app.directive(name, directive);
});

app
  .use(Toast)
  .use(store)
  .use(VueAxios, axios)
  .use(BootstrapVue3)
  .use(router)
  .mount("#app");
