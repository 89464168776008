export const required = msg => x => !x && msg;
export const min = min => msg => x => x.length >= min || msg;
export const minOrNull = min => msg => x => !x || x.length >= min || msg;
export const max = max => msg => x => x.length <= max || msg;
export const minMax = (min, max) => msg => x =>
  (min <= x.length && x.length <= max) || msg;
export const email = msg => x => /\S+@\S+\.\S+/.test(x) || msg;
export const lettersAndNumbersOnly = msg => x => /^[a-zA-Z0-9]+$/.test(x) || msg;
export const equal = msg => (...xs) => xs.every(x => x === xs[0]) || msg;
export const numberOrNull = msg => (x) => !x || !!Number(x) || msg;
export const number = msg => (x) => !!Number(x) || msg;
export const passwordRegex = msg => x => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/.test(x) || msg;
export const passwordRegexOrNull = msg => x => !x || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/.test(x) || msg;

// Copy and Paste example rules with type definition --->
//
// export const required = (msg: string) => (x: any) => !x && msg
// export const min = (min: number) => (msg: string) => (x: any) =>
//   x.length >= min || msg
// export const max = (max: number) => (msg: string) => (x: any) =>
//   x.length <= max || msg
// export const minMax = (min: number, max: number) => (msg: string) => (x: any) =>
//   (min <= x.length && x.length <= max) || msg
// export const email = (msg: string) => (x: any) => /\S+@\S+\.\S+/.test(x) || msg
// export const equal =
//   (msg: string) =>
//   (...xs: any[]) =>
//     xs.every(x => x === xs[0]) || msg
