<template>
    <div class="dialog" v-if="show" @click.stop="hideDialog">
        <div @click.stop class="dialog__content">
            <div @click="$.emit('update:show', false)" class="dialog__close">
                <svg-icon type="mdi" :path="mdiCloseCircleOutline" :size="20"></svg-icon>
            </div>
            <h4 class="headline">{{headline}}</h4>
            <div v-if="secondline" class="secondline">{{secondline}}</div>
            <div class="buttons">
                <b-button @click="confirmAction" variant="danger">{{positive}}</b-button>
                <b-button @click="this.$emit('update:show', false)" variant="success">{{negative}}</b-button>
              </div>
        </div>
    </div>
</template>
<script>
import { mdiCloseCircleOutline } from '@mdi/js';
export default {
    setup(){
        return {
            mdiCloseCircleOutline
        }
    },
    name: "my-dialog",
    props: {
        show: {
            type: Boolean,
            default: false
        },
        headline: {
            type: String,
            default: "Вы действительно хотите продолжить?"
        },
        secondline: {
            type: String,
            default: null
        },
        positive: {
            type: String,
            default: "Да"
        },
        negative: {
            type: String,
            default: "Нет"
        }
    },
    methods: {
        hideDialog(){
            this.$emit('update:show', false); 
        },
        confirmAction(){
            this.$emit('confirmed'); 
            this.hideDialog();
        }

    }
    
}
</script>
<style scoped lang="scss">
    .dialog {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        &__content {
            padding: 20px;
            position: relative;
            margin: auto;
            background-color: white;
            border-radius: 5px;
            min-height: 50px;
            min-width: 300px;
            .headline {
                margin: 35px 0px 15px 0px;
            }
            .secondline {
                text-align: center;
                color: rgb(139, 130, 130);
                margin-bottom: 20px;
            }
            .buttons {
                display: flex;
                justify-content: center;
                button {
                    min-width: 70px;
                    &:last-child {
                        margin-left: 10px;
                    }
                }
            }
            .dialog__close {
                position: absolute;
               // border: 2px black solid;
                border-radius: 45px;
                width: 30px;
                display: flex;
                justify-content: center;
                height: 30px;
                cursor: pointer;
                right: 5px;
                top: 10px;
            }
        }
    }
</style>