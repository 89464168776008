<template>
  <div class="dialog" v-if="show" @click="hideDialog">
    <div class="dialog__content">
      <div @click="$.emit('update:show', false)" class="dialog__close">
        <svg-icon
          type="mdi"
          :path="mdiCloseCircleOutline"
          :size="20"
        ></svg-icon>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { mdiCloseCircleOutline } from "@mdi/js";
export default {
  setup() {
    return {
      mdiCloseCircleOutline,
    };
  },
  name: "my-dialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    closeClickOutside: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hideDialog() {
      if (this.closeClickOutside) 
      this.$emit("update:show", false);
    },
  },
};
</script>
<style scoped lang="scss">
.dialog {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  &__content {
    padding: 20px;
    position: relative;
    margin: auto;
    background-color: white;
    border-radius: 5px;
    min-height: 50px;
    min-width: 300px;
    .dialog__close {
      position: absolute;
      // border: 2px black solid;
      border-radius: 45px;
      width: 30px;
      display: flex;
      justify-content: center;
      height: 30px;
      cursor: pointer;
      right: 5px;
      top: 10px;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
</style>
