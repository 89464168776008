import {DefaultAPIInstance} from '@/api';

export const AuthApi = {

    login(username,password) {
        const url = "/auth";
        const data = {username, password};
        data.platform = "m2admin";
        return DefaultAPIInstance.post(url, data);
    }

}