<template>
  <label :for="autId" v-if="label" class="label"
    >{{ label }} <span class="required" v-if="required && !disabled">*</span></label
  >
  <div class="_container" :class="attrsClassName">
    <input
      v-model="value"
      :id="autId"
      :class="['_input', { '_input-error': hasError }]"
      :disabled="disabled"
      :type="type"
      :placeholder="placeholder"
      v-bind="attrsRest"
    />
    <LoadingIcon
      class="_loading-icon"
      :class="{ '_loading-icon-error': hasError }"
      v-if="validating"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import LoadingIcon from "@/components/UI/LoadingIcon.vue";

export default defineComponent({
  inheritAttrs: false,
  components: { LoadingIcon },
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      required: true,
    },
    validating: {
      type: Boolean,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    type: {
      default: "text",
      type: String,
    },
    hasError: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { attrs }) {
    const { class: attrsClassName, id: autId, label, ...attrsRest } = attrs;
    return {
      attrsClassName,
      autId,
      attrsRest,
      label,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";

._container {
  position: relative;
}

._input {
  width: 100%;
  border: 1px solid $gray-400;
  outline: none;
  padding: 0.75rem;
  border-radius: 5px !important;

  &:focus {
    border-color: $green-400;
    box-shadow: 0 0 3px $green-400;
  }
  &:disabled {
    background-color: #e0d1d14d;
  }
}

.label:first-letter {
  text-transform: uppercase;
}

.required {
  color: red;
}

._input-error {
  border-color: $red-400 !important;

  &:focus {
    box-shadow: 0 0 3px $red-400;
  }
}

._loading-icon {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  color: $green-500;
}
._loading-icon-error {
  color: $red-400;
}
</style>
