<template>
  <section>
    <div class="container">
      <my-dialog v-model:show="dialogNewUserVisible">
        <user-form @success="userCreated"></user-form>
      </my-dialog>
      <div class="pathNav">
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </div>
      <div class="alerts">
        <div class="left">
          <svg-icon
            type="mdi"
            class="icon"
            :path="mdiAccountGroup"
            :size="35"
          ></svg-icon>
          <span>Сотрудники</span>
        </div>
        <div v-b-tooltip.hover.top="'Добавить нового сотрудника'" variant="primary" @click="dialogNewUserVisible = true" class="right add-user">
          <svg-icon type="mdi" :path="mdiAccountPlus" :size="35"></svg-icon>
        </div>
      </div>
      <div class="main">
        <div v-for="(user, i) in users" :key="user.id">
          <user-item
            :item="user"
            :num="i"
            :key="user.id"
            @remove="remove"
            @edit="edit"
          ></user-item>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { BaseApi } from "@/api/BaseApi";
import { useToast } from "vue-toastification";
import UserItem from "@/components/UserItem.vue";
import MyDialog from "@/components/UI/MyDialog.vue";
import UserForm from "@/components/UserForm.vue";
import { mdiAccountPlus, mdiAccountGroup } from "@mdi/js";

export default {
  setup() {
    return {
      mdiAccountPlus,
      mdiAccountGroup,
    };
  },
  data() {
    return {
      dialogNewUserVisible: false,
      users: [],
      breadcrumbs: [
        {
          text: "Сотрудники",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    remove(item) {
      BaseApi.deleteUser(item.id).then(({ data: r }) => {
        const toast = useToast();
        if (r.success) {
          toast.success(r.message);
          this.getUsers();
        } else {
          toast.error(r.message);
        }
        return r;
      });
    },
    getUsers() {
      this.$store.dispatch('spinnerStatus',true);
      BaseApi.userlist().then(({ data: r }) => {
        if (r.success) {
          this.users = r.data;
        }
        return r;
      }).finally(()=> this.$store.dispatch('spinnerStatus',false));
    },
    userCreated() {
      this.dialogNewUserVisible = false;
      this.getUsers();
    },
  },
  components: {
    UserItem,
    MyDialog,
    UserForm,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/constants.scss";
section {
  padding: 0px 15%;
  background: -moz-linear-gradient(
    -90deg,
    rgb(187, 202, 210) 0%,
    rgb(171, 183, 188) 100%
  );
  background: -webkit-linear-gradient(
    -90deg,
    rgb(187, 202, 210) 0%,
    rgb(171, 183, 188) 100%
  );
  background: -ms-linear-gradient(
    -90deg,
    rgb(187, 202, 210) 0%,
    rgb(171, 183, 188) 100%
  );
  background: linear-gradient(
    -90deg,
    rgb(187, 202, 210) 0%,
    rgb(171, 183, 188) 100%
  );
  min-height: $header-height;
  .container {
    .icon {
      color: lightgray;
    }
    .pathNav {
      padding: 10px 0;
      a {
        color: #518bb9;
        font-size: 18px;
      }
    }
    .add-user {
      color: $color-blue;
      cursor: pointer;
    }
    .alerts {
      height: 80px;
      padding: 0 28px 0 40px;
      align-items: center;
      background-color: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        @include display-align(flex, center);
        img {
          height: 40px;
        }
        span {
          font-size: 24px;
          color: #221e1f;
          vertical-align: middle;
          margin: 0 0 0 42px;
          line-height: 11px;
        }
        span.plantName {
          color: #518bb9;
          vertical-align: baseline;
          margin: 0;
        }
      }
      .right {
        img {
          cursor: pointer;
        }
      }
    }
    .main {
      padding: 27px 0;
      .tr.white {
        background-color: #f0f1f2;
      }
      .tr.grey {
        background-color: #bbcad2;
      }
      .alert {
        font-size: 18px;
      }
      .tr {
        min-height: 72px;
        @include display-align-justify(flex, center, space-between);
        padding: 0px 10px;
        flex-direction: row;
        .left {
          @include display-align(flex, center);
          flex-direction: row;
          max-width: 45%;
          width: 100%;
          .code {
            p {
              word-break: break-all;
              font-weight: bold;
              font-size: 21px;
              color: #4c504e;
            }
          }
          .data {
            margin-left: 15px;
            padding-left: 5px;
            //  border: 1px red solid;
            .code {
              font-weight: bold;
              font-size: 18px;
              color: #4c504e;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .note {
              font-size: 15px;
              color: rgb(137, 130, 130);
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .right {
          padding-right: 10px;
          display: flex;
          flex-direction: row;
          @include display-align-justify(flex, center, space-between);
          .edit,
          .delete {
            margin-right: 15px;
            height: 30px;
            width: 30px;
            &:hover {
              transform: scale(1.2);
            }
          }
          .objects {
            padding: 8px 10px;
            width: 100%;
            font-size: 18px;
            color: #ffffff;
            background-color: $color-blue;
          }
          &.transparent {
            border: 1px solid $color-blue;
            background-color: transparent;
            color: $color-blue;
          }
        }
      }
    }
  }
  // .pagination {
  //   margin-top: 10px;
  // }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
